<template>
<b-button :id="id" :block="isBlock" :disabled="isDisabled" :pill="isPill" :squared="isSquared" :title="title" :variant="variant" :to="to" class="d-flex flex-row align-items-center justify-content-center form-button">
    <template v-if="isLoading">
        <b-spinner small class="mr-2" variant="light"></b-spinner>
        <span class="text-small text-white">Loading...</span>
    </template>
    <template v-else>
        <slot></slot>
    </template>
</b-button>
</template>

<script>
/* eslint-disable no-console */
export default {
    name: "FormButton",
    props: {
        id: String,
        type: {
            default: "button",
            type: String,
        },
        title: {
            default: "Button",
            type: String,
        },
        size: {
            default: "md",
            type: String,
        },
        variant: {
            default: "primary",
            type: String,
        },
        to: {
            default: null,
            type: String,
        },
        isBlock: {
            default: false,
            type: Boolean,
        },
        isPill: {
            default: false,
            type: Boolean,
        },
        isSquared: {
            default: false,
            type: Boolean,
        },
        isDisabled: {
            default: false,
            type: Boolean,
        },
        isLoading: {
            default: false,
            type: Boolean,
        },
    },
};
</script>

<style scoped>
span.text-small {
    font-size: 0.9rem;
}

.form-button {
    min-height: 40px;
}
</style>
