import axios from "axios";

export const GetAllAdmins = async (params) => {
  try {
    return await axios.get("/admin/view_admins", { params });
  } catch (error) {
    return error;
  }
};

export const DeleteAdminMaster = async (payload) => {
  try {
    return await axios.delete(`/admin/delete_admin/${payload.id}`);
  } catch (error) {
    return error;
  }
};

export const ChangeAdminStatus = (payload) => {
  try {
    return axios.put(`/admin/admin_status_update/${payload.id}`, payload);
  } catch (error) {
    return error;
  }
};

export const reActiveAccount = ({ id }) => {
  try {
    return axios.get(`/admin/reactive_account/${id}`);
  } catch (error) {
    return error;
  }
};
