import axios from "axios";


export const GetAllCustomers = async (params) => {
    try {
      return await axios.get("/admin/view_customers",{ params });
    } catch (error) {
      return error;
    }
};

export const DeleteCustomer = async (payload) => {
  try {
    return await axios.delete(`/admin/delete_customer/${payload.id}`);
  } catch (error) {
    return error;
  }
};

export const SearchCustomer = ({ text }) => {
  return axios.get(`/admin/search_customers`, {
    params: { text },
  });
};

export const GetBusiness = async (payload) => {
  try {
    return await axios.get(`/admin/view_busness/${payload.id}`);
  } catch (error) {
    return error;
  }
};

export const ChangeCustomerStatus = (payload) => {
  try{
    return axios.put(`/admin/update_status/${payload.id}`, payload);
  }catch (error){
    return error;
  }
};