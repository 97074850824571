<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-center justify-content-center px-0 position-relative"
    >
      <!-- table container row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center table-card-row"
        no-gutters
      >
        <b-card no-body class="table-container-card">
          <!-- table actions row -->
          <b-row
            class="d-flex flex-row align-items-center justify-content-between w-100 my-1 my-md-2 px-lg-3 py-3"
            no-gutters
          >
            <!-- search input column -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-4 px-lg-0 py-2 py-lg-0"
              cols="12"
              md="4"
            >
              <!-- table search input -->
              <b-input-group class="search-input-group">
                <b-input-group-append>
                  <b-row
                    class="d-flex flex-row align-items-center justify-content-center px-3 border-1"
                    no-gutters
                  >
                    <b-button class="rounded-2" variant="transparent">
                      <b-icon
                        class="border-1"
                        style="color: rgba(135, 147, 163, 1)"
                        icon="search"
                        size="lg"
                        aria-label="Search"
                      ></b-icon>
                    </b-button>
                  </b-row>
                </b-input-group-append>
                <b-form-input
                  class="search-input border-1"
                  type="text"
                  placeholder="Search Customers"
                  v-model="searchTable"
                  @keyup.native.enter="searchFn"
                ></b-form-input>
                <b-input-group-append>
                  <b-row
                    class="d-flex flex-row align-items-center justify-content-center px-3 border-1 rounded-right"
                    no-gutters
                  >
                    <template v-if="searchTable">
                      <b-icon
                        class="text-main-green mr-2 border-1"
                        icon="x"
                        size="lg"
                        aria-label="Search"
                        @click="close"
                      ></b-icon>
                    </template>
                  </b-row>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <!-- table row -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-start px-3 w-100 table-container-row"
            no-gutters
          >
            <b-table
              ref="customerDetails"
              :bordered="false"
              :borderless="true"
              :hover="false"
              show-empty
              table
              class="table-element"
              thead-class="table-header"
              tbody-class="table-body"
              :busy="isLoading"
              :per-page="perPage"
              :fields="customersHeader"
              :items="customersItems"
              :sortDesc="true"
            >
              <!-- table busy state -->
              <template #table-busy>
                <div class="text-center text-muted my-3">
                  <b-spinner class="align-middle"></b-spinner>
                  <span class="text-left mx-1">Loading...</span>
                </div>
              </template>
              <!-- table empty state -->
              <template #empty="scope">
                <b-row
                  class="d-flex flex-row align-items-center justfiy-content-center py-5"
                  no-gutters
                >
                  <h6 class="text-center mb-0 mx-auto my-5">
                    {{ scope.emptyText }}
                  </h6>
                </b-row>
              </template>
              <!-- customer ID  -->
              <template #cell(ID)="data">
                <b-col
                  class="d-flex flex-column align-items-start justify-content-center px-0"
                >
                  <span class="main-text">{{ data.value }}</span>
                </b-col>
              </template>
              <!-- customer Name  -->
              <template #cell(CustomerNameBusiness)="data">
                <b-col
                  class="d-flex flex-column align-items-start justify-content-center px-0"
                >
                  <span class="main-text">{{ data.value.name }}</span>
                  <span class="text-muted sub-text">{{
                    data.value.business
                  }}</span>
                </b-col>
              </template>
              <!-- customer Joined Date -->
              <template #cell(JoinedDate)="data">
                <span class="main-text mb-2">{{ formatDate(data.value) }}</span>
              </template>
              <!-- customer Expiry Date  -->
              <template #cell(ExpiryDate)="data">
                <span class="main-text mb-2">{{ data.value }}</span>
              </template>
              <!-- Package Type -->
              <template #cell(PackageType)="data">
                <b-col
                  class="d-flex flex-column align-items-start justify-content-center px-0"
                >
                  <span class="main-text">{{ data.value }}</span>
                </b-col>
              </template>
              <!-- profile column -->
              <template #cell(Profile)="data">
                <template v-if="data.value == 'Active'">
                  <b-btn
                    class="font-weight-normal text-center"
                    style="background: #0b9b7d; width: 80px; border-radius: 5px"
                    ><span class="text-white">Active</span>
                  </b-btn>
                </template>
                <template v-if="data.value == 'Inactive'">
                  <b-btn
                    class="font-weight-normal text-center"
                    style="
                      background: white;
                      width: 80px;
                      border-radius: 5px;
                      border-color: #0b9b7d;
                    "
                    ><span style="color: #0b9b7d">Inactive</span></b-btn
                  >
                </template>
              </template>
              <!-- status column -->
              <template #cell(Status)="data">
                <template v-if="data.value == 'active'">
                  <b-button
                    class="status-btn rounded-circle px-2"
                    @click="statusInfo(data.item.ID, 'inactive', true)"
                  >
                    <b-icon
                      icon="check-lg"
                      aria-label="View Status"
                      class="mx-auto status-icon"
                      size="lg"
                      scale="1.0"
                    ></b-icon>
                    <!-- </b-button> -->
                  </b-button>
                </template>
                <template v-if="data.value == 'inactive'">
                  <b-button
                    class="rounded-circle px-2"
                    style="
                      background: #ffffff;
                      width: 40px;
                      height: 40px;
                      text-align: center;
                      border: 1px solid #9fa2b4;
                    "
                    @click="
                      statusInfo(
                        data.item.ID,
                        'active',
                        true,
                        data.item.CustomerNameBusiness.name
                      )
                    "
                  >
                    <b-icon
                      icon="check-lg"
                      aria-label="View Status"
                      class="mx-auto"
                      style="color: #9fa2b4"
                      size="lg"
                      scale="1.0"
                    ></b-icon>
                    <!-- </b-button> -->
                  </b-button>
                </template>

                <template v-if="data.value == 'locked'">
                  <b-button
                    class="status-btn rounded-circle px-2"
                    @click="activeUser(data.item.ID)"
                  >
                    <!-- @click="statusInfo(data.item.ID, 'inactive', true)" -->
                    <b-icon
                      icon="lock"
                      aria-label="View Status"
                      class="mx-auto status-icon"
                      size="lg"
                      scale="1.0"
                    ></b-icon>
                    <!-- </b-button> -->
                  </b-button>
                </template>
              </template>

              <!-- actions column -->
              <template #cell(actions)="data">
                <b-button
                  id="dropdown-left"
                  text=""
                  class="shadow-sm mx-1 delete-btn rounded-circle px-2"
                  @click="
                    handleButtonClick(
                      data.item.ID,
                      data.item.CustomerNameBusiness.name,
                      dltBtn
                    )
                  "
                >
                  <!-- @click="
                      sendInfo(
                        data.item.ID,
                        data.item.CustomerNameBusiness.name,
                        dltBtn
                      )
                    " -->
                  <b-icon
                    icon="trash"
                    aria-label="View delete Actions"
                    class="mx-auto delete-icon"
                    size="lg"
                    font-scale="1.2"
                  ></b-icon>
                  <!-- </b-button> -->
                </b-button>
              </template>
              <!-- ViewMore column -->
              <template #cell(ViewMore)="data">
                <b-button
                  id="dropdown-left"
                  text=""
                  class="shadow-sm ml-auto more-btn rounded-circle"
                  @click="
                    ViewMoreDetails(
                      data.item.Business_Id,
                      data.item.Email,
                      data.item.CustomerNameBusiness.name,
                      data.item.ExpiryDate
                    )
                  "
                >
                  <b-icon
                    icon="chevron-right"
                    aria-label="View More Actions"
                    class="mx-auto more-icon"
                    size="lg"
                    font-scale="1.2"
                  ></b-icon>
                  <!-- </b-button> -->
                </b-button>
              </template>
            </b-table>
          </b-row>
          <!-- table controls -->
          <!-- safari fix (flex-shrink-0) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-start justify-content-md-end flex-shrink-0 px-3 py-2 w-100"
            no-gutters
          >
            <!-- pages per page control -->
            <b-col
              class="d-flex flex-column align-items-start align-items-xl-start justify-content-start py-2 py-md-1"
              cols="12"
              sm="6"
              lg="5"
              xl="2"
            >
              <b-form-group
                label="Per page"
                label-cols="12"
                label-cols-sm="6"
                label-align="left"
                label-align-sm="right"
                label-size="sm"
                label-for="perPageSelect"
                class="mb-0 w-100"
              >
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="sm"
                  class="per-page-select border-1 border-main-green py-0"
                  :options="pageOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <!-- pagination controls -->
            <b-col
              class="d-flex flex-column align-items-center align-items-sm-end justify-content-center py-2 py-md-1"
              cols="6"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                size="md"
                limit="4"
                pills
                class="table-pagination align-items-center justify-content-center"
                page-class="custom-page-item"
                ellipsis-class="custom-page-item"
                first-class="custom-page-item"
                last-class="custom-page-item"
                next-class="custom-page-item"
                prev-class="custom-page-item"
              >
                <template #first-text>
                  <b-icon
                    class="text-main-green"
                    icon="chevron-left"
                    size="sm"
                    aria-label="Plus"
                  ></b-icon>
                </template>
                <template #prev-text>
                  <b-icon
                    class="text-main-green"
                    icon="chevron-double-left"
                    size="sm"
                    aria-label="Plus"
                  ></b-icon>
                </template>
                <template #next-text>
                  <b-icon
                    class="text-main-green"
                    icon="chevron-double-right"
                    size="sm"
                    aria-label="Plus"
                  ></b-icon>
                </template>
                <template #last-text>
                  <b-icon
                    class="text-main-green"
                    icon="chevron-right"
                    size="sm"
                    aria-label="Plus"
                  ></b-icon>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <b-modal
        id="modal-center"
        size="lg"
        centered
        v-model="show"
        @hide="closeModal()"
        title="Are you sure you want to delete this customer?"
      >
        <template #modal-header-close>
          <!-- Emulate built in modal header close button action -->
          <!-- <h6>Are you sure you want to delete this customer?</h6> -->
          <b-icon
            icon="x-circle"
            class="text-dark"
            @click="closeModal()"
            aria-label="Close Modal"
            font-scale="0.8"
          ></b-icon>
        </template>
        <p class="my-4">
          Are you sure you want to delete this customer {{ this.custome_name }}?
          All the data will be permanently removed. This action cannot be
          undone.
        </p>
        <template #modal-footer="{}">
          <b-row flex-row align-items-center justify-content-center>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
              style="padding-right: 0px"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                style="background-color: #bdbdbd; border: none"
                @click.native="closePopup"
                ><span class="text-white">Cancel</span></FormButton
              >
            </b-col>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                @click.native="ActionBtnFn"
                style="background-color: #0b9b7d; border: none"
                ><span class="text-white">Delete</span></FormButton
              >
            </b-col>
          </b-row>
        </template>
      </b-modal>
      <b-modal
        id="modal-center"
        size="lg"
        title="Are you sure you want to enable this customer?"
        centered
        v-model="showStatusEnable"
        @hide="closeModal()"
      >
        <template #modal-header-close>
          <!-- Emulate built in modal header close button action -->
          <!-- <h6>Are you sure you want to enable this customer?</h6> -->
          <b-icon
            icon="x-circle"
            class="text-dark"
            @click="closeModal()"
            aria-label="Close Modal"
            font-scale="0.8"
          ></b-icon>
        </template>
        <p class="my-4">
          Are you sure you want to enable this customer {{ this.custome_name }}?
          This action can always be revoked.
        </p>
        <template #modal-footer="{}">
          <b-row flex-row align-items-center justify-content-center>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
              style="padding-right: 0px"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                style="background-color: #bdbdbd; border: none"
                @click.native="closePopup"
                ><span class="text-white">Cancel</span></FormButton
              >
            </b-col>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                @click.native="ActionBtnFn"
                style="background-color: #0b9b7d; border: none"
                ><span class="text-white">Enable</span>
              </FormButton>
            </b-col>
          </b-row>
        </template>
      </b-modal>
      <b-modal
        id="modal-center"
        size="lg"
        title="Are you sure you want to disable this customer?"
        centered
        v-model="showStatusDisable"
        @hide="closeModal()"
      >
        <template #modal-header-close>
          <!-- Emulate built in modal header close button action -->
          <!-- <h6>Are you sure you want to disable this customer?</h6> -->
          <b-icon
            icon="x-circle"
            class="text-dark"
            @click="closeModal()"
            aria-label="Close Modal"
            font-scale="0.8"
          ></b-icon>
        </template>
        <p class="my-4">
          Are you sure you want to disable this customer
          {{ this.custome_name }}? This action can always be revoked.
        </p>
        <template #modal-footer="{}">
          <b-row flex-row align-items-center justify-content-center>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
              style="padding-right: 0px"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                style="background-color: #bdbdbd; border: none"
                @click.native="closePopup"
                ><span class="text-white">Cancel</span></FormButton
              >
            </b-col>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
            >
              <FormButton
                isPill
                class="font-weight-normal text-secondary mt-2 login-button"
                @click.native="ActionBtnFn"
                style="background-color: #0b9b7d; border: none"
                ><span class="text-white">Disable</span>
              </FormButton>
            </b-col>
          </b-row>
        </template>
      </b-modal>
      <b-modal id="account-active" centered title="Account ReActivation">
        <template>
          <div>
            <p class="font-weight-bold">Do you want reactivate this user ?</p>
            <!-- <label for="example-datepicker">Choose a active ending date</label>
            <b-form-datepicker
              id="example-datepicker"
              v-model="value"
              class="mb-2"
              :min="minDate"
              :max="maxDate"
            ></b-form-datepicker> -->
            <p>
              Account Activate until:
              <span class="text-danger">{{ value }}</span>
            </p>
          </div>
        </template>
        <template #modal-footer>
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="success" @click="cancel()">
            Cancel
          </b-button>
          <b-button size="sm" variant="danger" @click="reActivateUser()">
            <span v-if="isActivating">Activating.....</span>
            <span v-else>Activate</span>
          </b-button>
          <!-- Button with custom close trigger value -->
        </template>
      </b-modal>
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import FormButton from "@/components/Form/FormButton";

// services
import {
  GetAllCustomers,
  SearchCustomer,
  DeleteCustomer,
  ChangeCustomerStatus,
} from "@/services/customer_business.service";

import { reActiveAccount } from "@/services/administrators.service";

export default {
  name: "Dashboard",
  components: {
    HomeLayout,
    FormButton,
  },
  data() {
    return {
      // isShowDeleteItemRow: false,
      // showDeleteCustomerModalKey: Number(new Date()) + 100,
      value: "",
      isActivating: false,
      minDate: null,
      maxDate: null,
      reActivateUserId: null,
      rowItem: null,
      rowIndex: null,
      show: false,
      showStatusEnable: false,
      showStatusDisable: false,
      C_ID: null,
      status: "",
      Email: "",
      statusBtn: false,
      custome_name: null,
      dltBtn: false,
      emailVerify: false,
      // filterDropDownShow: false,

      searchTable: null,
      isLoading: false,
      customersHeader: [
        {
          key: "ID",
          label: "ID",
          sortable: true,
        },
        {
          key: "CustomerNameBusiness",
          label: "Customer Name & Business",
          sortable: true,
        },
        {
          key: "JoinedDate",
          label: "Joined Date",
          sortable: true,
        },
        {
          key: "ExpiryDate",
          label: "Expiry Date",
          sortable: true,
        },
        {
          key: "PackageType",
          label: "Package Type",
        },
        {
          key: "lastUsedCoupon",
          label: "Last Used Coupon",
        },
        {
          key: "Status",
          label: "Status",
        },
        {
          key: "actions",
          label: "Action",
        },
        {
          key: "ViewMore",
          label: "View More",
        },
      ],
      customersItems: [],
      // table pagination controls
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
    };
  },
  async mounted() {
    await this.initFn();
    this.setMinDate();
    this.setMaxDate();
    // await this.loadCurrency();
  },
  watch: {
    searchTable() {
      this.searchFn();
    },
    perPage() {
      this.initFn();
    },
    currentPage() {
      this.initFn();
    },
  },
  methods: {
    setMinDate() {
      const currentDate = new Date();
      // const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      // const minDate = new Date(today);
      // minDate.setMonth(minDate.getMonth());
      currentDate.setMonth(currentDate.getMonth() + 1);
      let month = currentDate.getMonth() + 1;
      let days = currentDate.getDate();
      const year = currentDate.getFullYear();
      if (days < 10) days = `0${days}`;
      if (month < 10) month = `0${month}`;
      this.value = `${year}/${month}/${days}`;
    },
    activeUser(data) {
      this.$bvModal.show("account-active");
      this.reActivateUserId = data;
    },
    async reActivateUser() {
      try {
        this.isActivating = true;
        await reActiveAccount({
          id: this.reActivateUserId,
          timePeriod: this.value,
        });
        this.isActivating = false;
        await this.initFn();
        this.$bvToast.toast("User ReActivated successfully", {
          title: "ReActivated Successfully",
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
        this.$bvModal.hide("account-active");
      } catch (error) {
        this.$bvToast.toast(error.response.data.msg, {
          title: "Something Went Wrong!",
          toaster: "b-toaster-bottom-right",
          variant: "danger",
          solid: true,
        });
        this.isActivating = false;
        this.$bvModal.hide("account-active");
      }
    },
    cancel() {
      this.reActivateUserId = null;
      this.$bvModal.hide("account-active");
    },
    async initFn(val = undefined) {
      try {
        if (val) this.currentPage = val;
        this.isLoading = true;
        let { data } = await GetAllCustomers({
          limit: this.perPage,
          page: this.currentPage,
        });
        this.totalRows = data.total;
        this.customersItems = data.results.map((x) => {
          return {
            ID: x._id,
            CustomerNameBusiness: {
              name: x.name,
              business: x.business_name,
            },

            JoinedDate: x.created_at.split("T")[0],
            ExpiryDate: x.expiredate.split("T")[0],
            PackageType: x.userPacage,
            lastUsedCoupon: x.coupon,
            Profile: x.Profile,
            Status: x.Status,
            Business_Id: x.business_id,
            Email: x.email,
            emailVerify: x.email_confirmed,
          };
        });

        this.dltBtn = false;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    ViewMoreDetails(id, email, name, expiryDate) {
      // this.$router.push("/customerDetails/" + id);
      this.$router.push({
        path: `/customerDetails/${id}`,
        query: {
          email,
          name,
          expiryDate,
        },
      });
    },

    async searchCustomers() {
      try {
        this.customersItems = [];
        this.isLoading = true;
        let { data } = await SearchCustomer({ text: this.searchTable });
        this.customersItems = data.map((x) => {
          return {
            ID: x._id,
            CustomerNameBusiness: {
              name: x.name,
              business: x.business_name,
            },
            JoinedDate: x.created_at.split("T")[0],
            ExpiryDate: x.expiredate.split("T")[0],
            Profile: x.Profile,
            Status: x.Status,
            Business_Id: x.business_id,
            Email: x.email,
            emailVerify: x.email_confirmed,
          };
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },

    searchFn() {
      if (this.searchTable && this.searchTable.length > 0) {
        this.searchCustomers();
      } else {
        this.isLoading = false;
        this.initFn();
      }
    },

    close() {
      this.searchTable = null;
      this.initFn();
    },
    closePopup() {
      this.show = false;
      this.showStatusDisable = false;
      this.showStatusEnable = false;
    },
    async ActionBtnFn() {
      if (this.dltBtn) {
        console.log("this is first");
        this.isLoading = true;
        let payload = {
          id: this.C_ID,
        };
        let { data } = await DeleteCustomer(payload);
        console.log("customer deleted", data);
        this.isLoading = false;

        this.$bvToast.toast("Customer deleted successfully", {
          title: "Deleted Successfully",
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
        this.initFn();
        this.dltBtn = false;
        // Close the popup
        this.show = false;
      } else if (this.statusBtn === true && this.status === "active") {
        let payload = {
          id: this.C_ID,
          status: this.status,
        };
        let { data } = await ChangeCustomerStatus(payload);
        this.initFn();
        this.showStatusEnable = false;
        this.customersItems = data.map((x) => {
          return {
            Status: x.status,
          };
        });
      } else if (this.statusBtn === true && this.status === "inactive") {
        let payload = {
          id: this.C_ID,
          status: this.status,
        };
        let { data } = await ChangeCustomerStatus(payload);
        this.initFn();
        this.showStatusDisable = false;
        this.customersItems = data.map((x) => {
          return {
            Status: x.status,
          };
        });
      }
    },

    statusInfo(id, status, statusBtn, cname) {
      this.C_ID = id;
      this.status = status;
      this.custome_name = cname;
      this.statusBtn = statusBtn;
      if (this.status == "active") {
        this.showStatusEnable = true;
      } else {
        this.showStatusDisable = true;
      }
    },
    handleButtonClick(id, cname) {
      this.sendInfo(id, cname, true);
    },
    sendInfo(id, cname, dltBtn) {
      this.C_ID = id;
      this.custome_name = cname;
      this.dltBtn = dltBtn;
      this.show = true;
    },
    closeModal() {
      this.dltBtn = false;
      this.$emit("onClose");
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
  },
};
</script>

<style scoped>
/* table card */
.table-card-row {
  height: 80vh;
  width: 90vw;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  box-sizing: border-box;
  position: relative;
  /* width: calc(100% - 100px); */
  width: 100%;
  /* height: 80vh; */
  padding: 10px;
  border-radius: 16px;

  border: 1px solid rgba(135, 147, 163, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  min-width: 200px;
  border-radius: 5px;
  border: 1px solid rgba(135, 147, 163, 0.25);
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 45px;
  border-radius: 4px;
  overflow: hidden;
  border: none;
  /* min-width: 180px; */
}

.table-card-row .search-input-group .search-input::placeholder {
  color: #8793a3;
}

.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}

.table-card-row .add-question-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
}

.table-card-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 1000px;
}

/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #828282;
}

.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 16px;
  font-weight: 400;
  border-color: transparent;
}

.table-element >>> .table-body td {
  vertical-align: middle;
}

.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}

.table-element >>> .table-body .main-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}

/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}

/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}

/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  background: #ffffff;
  width: 40px;
  height: 40px;

  border: 1px solid #828282;
}

.table-element >>> .table-body .more-btn > .more-icon {
  color: #828282;
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: #828282;
}

.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: #ffffff;
}

/* status-btn */
.table-element >>> .table-body .status-btn {
  background: #ffffff;
  width: 40px;
  height: 40px;
  text-align: center;
  border: 1px solid #41873f;
}

.table-element >>> .table-body .status-btn > .status-icon {
  color: #41873f;
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element >>> .table-body .status-btn:hover {
  background-color: #41873f;
}

.table-element >>> .table-body .status-btn:hover > .status-icon {
  color: var(--white);
}

/* table question action buttons */
/* edit icon button (default state) */

/* delete icon button (default state) */
.table-element >>> .table-body .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  border: 1px solid var(--danger);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}

/* delete icon button (hover state) */
.table-element >>> .table-body .delete-btn:hover {
  background-color: var(--danger);
}

.table-element >>> .table-body .delete-btn:hover > .delete-icon {
  color: var(--white);
}

/* expand icon button (default state) */
.table-element >>> .table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .expand-btn > .expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}

/* expand icon button (hover state) */
.table-element >>> .table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .expand-btn:hover > .expand-icon {
  color: var(--white);
}

.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}

.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}

/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}

/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #333333;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 38px;
  height: 34px;

  border: transparent;
  border-radius: 50%;
  transition: all 150ms ease-in;
}

.table-pagination >>> .custom-page-item button:hover {
  color: #0b9b7d;
  /* border-color: var(--secondary); */
  background-color: var(--light);
}

.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: #0b9b7d;
  border: none;
  border-color: transparent;
  font-weight: bold;
  background-color: transparent;
}

.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  opacity: 0.5;
  border-color: transparent;
  background-color: transparent;
}

.login-button {
  border-radius: 30px;
  width: 142px;
  height: 30px;
  background: #0b9b7d;
}

/* Small devices (landscape phones, less than 768px) */
/* @media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }
} */
</style>
